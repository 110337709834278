.search_player_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-top: 50px;
}

.submit_classic_button {
    color: #252525;
    font-weight: 600;
    background-color: #45FFA6;
    border: 1px solid #45FFA6;
    border-radius: 10px;
    padding: 8px 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 350ms ease-in-out;
    &:hover {
        color: #45FFA6;
        border-color: #45FFA6;
        background-color: transparent;
    }
}

.search_player_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.select_player_button {
    border-radius: 18px;
    background-color: #45FFA6;
    color: #252525;
    padding: 8px 15px;
    border: 1px solid #45FFA6;
    transition: all 500ms ease;
    &:hover {
        background-color: transparent;
        color: #45FFA6;
    }
}

.search_player_alert {
    width: 70%;
    text-align: center;
    font-size: 15px;
    padding: 10px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.search_player_alert.alert-dismissible .btn-close {
    padding: 0;
    position: relative;
    border: none;
    box-shadow: none;
}


/*--------------------- PLAYER BAR ---------------------*/


.search_player_bar {
    width: 100%;
}

.search_player_bar .form-control::placeholder {
    color: #bebebe !important;
}

.search_player_bar .form-control {
    border: 1px solid #40444b;
    width: 100%;
    margin-bottom: 0;
    background-color: #40444b;
    transition: all 500ms ease;
    color: #bebebe;
    &:hover {
        border-color: #45FFA6;
    }
    &:focus {
        border-color: #45FFA6;
        color: #fff !important;
    }
}

.searched_players_nickname {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    font-size: 15px;
    width: 100%;
    background-color: transparent;
    padding: 10px;
    color: #fff;
    border: #fff 3px solid;
    max-height: 40%;
    margin-top: 5%;
    font-weight: 400;
}

.searched_players_nickname:first-child {
    margin-top: 0;
}


/*--------------------- PLAYERS BLOCK ---------------------*/


.players_selecor_block {
    border-radius: 10px;
    width: 100%;
    padding: 5%;
    background-color: #353434;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
}
.players_selecor_block::-webkit-scrollbar {
    width: 5px;
    height: 0.01px;
}
.players_selecor_block::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom right, #f1f1f1 0%, #fafafa 100%);
    border-radius: 30px;
}
.players_selecor_block::-webkit-scrollbar-track:horizontal {
    background-color: white;
    box-shadow: inset 0 0 2px 2px gainsboro;
}

.searched_players_nickname_logo {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #343434;
    margin-left: 0.5%;
}

.searched_players_nickname_plus {
    transition: color 700ms ease;
    color: #fff;
    margin-right: 0.5%;
    &:hover {
        color: #45FFA6 
    }
}

.searched_players_nickname_playername {
    width: 75%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    
    a {
        text-decoration: none;
        color: #fff;
        transition: color 500ms ease;
        &:hover{
            color:#10D4FF;
        }
    }
}