.main_container {
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 11vh 5.83vw;
    background: #121212;
    font-family: "Tektur", serif;
    overflow: hidden;
}

.main_header {
    width: 100%;
    height: 90vh;
    position: relative;
    margin-bottom: 11.66vw;
    display: flex;
    justify-content: center;

    .main_header_nevaText {
        font-size: 5.5vw;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1;

        span {
            color: #45FFA6;
        }
    }

    .main_header_imgContaienr {
        .main_header_img {
            height: 100%;
            max-width: 100%
        }
    }

    .main_header_desc {
        text-align: end;
        align-content: end;
        position: absolute;
        bottom: 8vw;;
        right: 0;

        p {
            margin-bottom: 2.6vh;
            text-align: right;
            font-size: 1vw;
            color: #A0A0A0;
            font-family: "Jura", serif;
        }

        .main_header_descButton {
            color: #45FFA6;
            font-size: 1.2vw;
            border: 0.138vw solid #45FFA6;
            border-radius: 0.7vw;
            width: 19vw;
            height: 3vw;
            background: transparent;
            box-shadow: transparent 0 0 0 0;
            transition: all 600ms linear(
                    0 0%, 0.22 2.1%, 0.86 6.5%, 1.11 8.6%, 1.3 10.7%, 1.35 11.8%, 1.37 12.9%,
                    1.37 13.7%, 1.36 14.5%, 1.32 16.2%, 1.03 21.8%, 0.94 24%, 0.89 25.9%, 0.88
                    26.85%, 0.87 27.8%, 0.87 29.25%, 0.88 30.7%, 0.91 32.4%, 0.98 36.4%, 1.01
                    38.3%, 1.04 40.5%, 1.05 42.7%, 1.05 44.1%, 1.04 45.7%, 1 53.3%, 0.99 55.4%,
                    0.98 57.5%, 0.99 60.7%, 1 68.1%, 1.01 72.2%, 1 86.7%, 1 100%
            );

            &:hover {
                transform: translateY(-0.5vw);
                box-shadow: #121212 0 0.5vw 0 0, #45FFA6 0 0.5vw 0 0.06vw;
            }
        }
    }
}

.neva_aboutAs {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 11.66vw;
    gap: 9vw;

    .neva_aboutAs_title {
        font-size: 3vw;
        line-height: 1;
        width: 50%;
    }

    .neva_aboutAs_subTitles {
        display: flex;
        flex-direction: column;
        font-size: 0.9vw;
        width: 50%;
        margin-left: 50%;

        .neva_aboutAs_link {
            text-decoration: none;
            
            .neva_aboutAs_subTitle {
                color: #45FFA6;
                line-height: 2;
            }
        }

        .neva_aboutAs_subTitleDesc {
            color: #A0A0A0;
        }
    }
}

.about_nevaWrapper {
    width: 100%;
    margin-bottom: 11.66vw;

    .about_neva_upperTitle {
        font-size: 1.3vw;
        color: #45FFA6;
        margin-bottom: 1.6vw;
    }

    .about_neva {
        width: 100%;
        height: 33vw;
        display: flex;
        justify-content: space-between;

        .about_neva_subtitle {
            color: #45FFA6;
            font-size: 1.7vw;
            margin-bottom: 1.66vw;
        }

        .about_neva_content {
            width: 45%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .about_neva_content_block {
                .about_neva_title {
                    font-size: 2.77vw;
                    margin-bottom: 4.2vw;
                    line-height: 1;
                }

                .about_neva_desc {
                    font-size: 1.11vw;
                    font-family: "Jura", serif;
                    color: #A0A0A0;
                }
            }

            .about_neva_content_block2 {
                width: 100%;

                .about_neva_buttonsTitle {
                    font-family: "Jura", serif;
                    font-size: 1.66vw;
                    margin-bottom: 2vw;
                    line-height: 1.66vw;
                }

                .about_neva_buttons {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .about_neva_button {
                        width: 4vw;
                        height: 4vw;
                        border-radius: 50%;
                        background: transparent;
                        border: 0.138vw solid #45FFA6;
                        margin-right: 9vw;
                        cursor: pointer;
                        color: #00FE88;
                        box-shadow: transparent 0 0 0 0;
                        transition: all 600ms linear(
                                0 0%, 0.22 2.1%, 0.86 6.5%, 1.11 8.6%, 1.3 10.7%, 1.35 11.8%, 1.37 12.9%,
                                1.37 13.7%, 1.36 14.5%, 1.32 16.2%, 1.03 21.8%, 0.94 24%, 0.89 25.9%, 0.88
                                26.85%, 0.87 27.8%, 0.87 29.25%, 0.88 30.7%, 0.91 32.4%, 0.98 36.4%, 1.01
                                38.3%, 1.04 40.5%, 1.05 42.7%, 1.05 44.1%, 1.04 45.7%, 1 53.3%, 0.99 55.4%,
                                0.98 57.5%, 0.99 60.7%, 1 68.1%, 1.01 72.2%, 1 86.7%, 1 100%
                        );

                        &:hover {
                            transform: translateY(-0.5vw);
                            box-shadow: #121212 0 0.5vw 0 0, #45FFA6 0 0.5vw 0 0.06vw;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        .about_neva_buttonIcon {
                            width: 60%;
                            height: 60%;
                            fill: #45FFA6;
                        }
                    }

                    .about_neva_button.active {
                        background: #45FFA6;

                        .about_neva_buttonIcon {
                            fill: #121212;
                        }
                    }
                }
            }
        }

        .about_neva_imgContainer {
            width: 50%;
            height: 100%;

            .about_neva_img {
                border-radius: 0.7vw;
                text-align: center;
                align-content: end;
                overflow: hidden;
                position: relative;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top;

                span {
                    font-size: 30px;
                }

                .about_neva_img_desc {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;

                    padding: 1vw 2vw;
                    font-size: 1.11vw;
                    font-family: "Jura", serif;
                    color: #fff;
                    text-align: left;
                    background: linear-gradient(
                            to top,
                            rgba(255,255,255,0.4) 0%,
                            rgba(255,255,255,0.05) 30%,
                            rgba(255,255,255,0) 70%
                    );

                    backdrop-filter: blur(10px);
                    -webkit-backdrop-filter: blur(10px); /* для Safari */
                }
            }
        }
    }
}

.main_serversMap_wrapper {
    position: relative;
    width: 100vw;
    margin-left: calc(-5.83vw);
    margin-right: calc(-5.83vw);
}

.neva_staple {
    width: 100%;
    margin-bottom: 11.66vw;
    position: relative;

    .neva_staple_content_blockCercle {
        width: 3vw;
        height: 3vw;
        position: absolute;
        top: 230px;
        border-radius: 50%;
        z-index: 1;
    }

    .neva_staple_Titles {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .neva_staple_Title {
            line-height: 1;
            font-size: 2.77vw;
        }

        .neva_staple_SubTitle {
            color: #A0A0A0;
            text-align: end;
            font-size: 0.9vw;
        }
    }

    .neva_staple_content {
        width: calc(100% + 11.66vw);
        margin-left: -5.83vw;
        margin-right: -5.83vw;
        height: 56vw;
        background-image: url('./assets/stapleBg.png');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: space-between;
        padding: 0 5.83vw;

        .neva_staple_content_block {
            margin-top: 8vw;
            width: 30%;
            text-align: center;
            border-radius: 0.7vw;
            height: 13vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 1vw;
            padding: 2vw 0;
            font-family: "Jura", serif;
            background-image: url("./assets/stapleBlockBg.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;

            .neva_staple_content_blockCircl {
                width: 5vw;
                height: 5vw;
                background: #45FFA6;
                border-radius: 50%;
                position: absolute;
                top: -18%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 3vw;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #121212;
            }

            .neva_staple_content_blockTitle {
                font-size: 1.3vw;
                line-height: 1;
                height: 3vw;
                align-content: center;
                text-shadow: 4px 4px 9px rgba(0,0,0,0.25);
            }

            .neva_staple_content_blockSubTitle {
                font-size: 0.9vw;
                line-height: 1;
                color: #1A1A1A;
                height: 3vw;
                text-shadow: 4px 4px 9px rgba(0,0,0,0.25);
            }
        }
    }
}

.main_partners {
    width: 100%;
    margin-bottom: 11.66vw;

    .main_partners_descs {
        width: 100%;
        display: flex;
        align-items: flex-end;
        padding: 0 5.83vw;
        margin-bottom: 5vw;

        .main_partners_desc {
            width: 50%;
            height: 100%;

            .main_partners_descTitle {
                font-size: 3vw;
                margin-bottom: 2vw;
                line-height: 1;
            }

            .main_partners_descSubTitle {
                color: #45FFA6;
                font-size: 1.5vw;
            }

            .main_partners_descText {}
        }

        .main_partners_desc.two {
            text-align: end;
            align-content: end;

            .main_partners_descText {
                display: inline-block;
                text-align: start;
                font-size: 1vw;
                color: #A0A0A0;
            }
        }
    }

    .partners_carousel {
        width: 100%;
        padding: 20px 0;
        overflow: hidden;
        display: flex;

        > * {
            flex: 0 0 100%;
        }

        .partners_carouselGroup {
            width: 100%;
            display: flex;
            gap: 20px;
            padding-right: 20px;
            will-change: transform;
            animation: scrolling 10s linear infinite;

            .partners_partner {
                flex: 0 0 25%;
                height: auto;
                padding: 20px;
                background: transparent;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                box-sizing: border-box;
                align-items: center;

                .partners_partnerIcon {
                    width: 50%;
                }
            }
        }
    }
}

.main_questions {
    width: 100%;
    height: 43vw;
    margin-bottom: 5vw;

    .main_questionsTitles {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .main_questionsTitle {
            font-size: 3vw;
        }

        .main_questionsSubTitle {
            color: #A0A0A0;
            font-size: 1.25vw;
        }
    }

    .main_questions_content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .main_questions_content_questions {
            width: 48%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 4vw;

            .main_questions_content_questionsCard {
                padding: 1.5vw 1vw;
                border: 0.07vw solid #45FFA6;
                border-radius: 0.7vw;
                color: #45FFA6;
                cursor: pointer;
                position: relative;
                transition: all 0.3s ease;
            }

            .main_questions_content_questionsCardHeadline {
                color: #45FFA6;
                font-size: 0.9vw;
                user-select: none;
                cursor: pointer;
                position: relative;
            }

            .main_questions_content_questionsArrowBottom {
                position: absolute;
                top: 1.5vw;
                right: 1vw;
                transition: transform 400ms ease;
            }
            .main_questions_content_questionsArrowBottom.active {
                transform: rotate(-180deg);
            }

            .main_questions_content_questionsCardDesc {
                font-size: 0.8vw;
                color: #fff;
                line-height: 1.5;
                height: 0;
                overflow: hidden;
                transition: height 0.4s ease;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            .main_questions_content_questionsCardDesc.active {
                height: 6vw;
            }

            .main_questions_content_questionsCardDesc p {
                transform: translateY(100%);
                transition: transform 0.4s ease, opacity 0.4s ease;
                opacity: 0;
            }

            .main_questions_content_questionsCardDesc.active p {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    .main_questions_content_imgContainer {
        width: 48%;

        .main_questions_content_img {
            width: 100%;
        }
    }
}

.neva_games {
    width: 100%;
    margin-bottom: 11.66vw;
    position: relative;

    .neva_games_title {
        font-size: 2.3vw;
        line-height: 1;
    }

    .react-multi-carousel-list  {
        height: 35vw !important;

        .react-multi-carousel-track {
            height: 100% !important;
        }
    }

    .neva_games_custom_buttons {
        display: flex;
        gap: 1.66vw;
        position: absolute;
        left: 25.6%;
        bottom: 0;

        .neva_games_custom_button {
            width: 3vw;
            height: 3vw;
            background: transparent;
            border: 1px solid #A0A0A0;
            color: #A0A0A0;
            border-radius: 0.7vw;

            .neva_games_custom_arrowIcon {
                width: 25%;
                height: 25%;
            }

            &:hover {
                border-color: #45FFA6;
                color: #45FFA6;
            }
        }
    }

    .neva_games_cardItem {
        display: flex;
        justify-content: center;
        align-items: center;

        .neva_games_cardItem_img {
            width: 70%;
            height: auto;
            cursor: pointer;
            border-radius: 0.7vw;
            user-select: none;
            transition: width 550ms ease;
            z-index: 100000;
            box-shadow: 0px 1px 45px 0px rgba(255, 255, 255, 0.2);
        }

        .neva_games_cardItem_img.active {
            transition: width 550ms ease;
            width: 100%;
        }
    }
}

.main_join {
    width: 100%;
    height: 21vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 4vw;
    margin-bottom: 11.66vw;
    justify-content: center;
    align-items: center;


    .main_joinTitle {
        font-size: 2.5vw;
        line-height: 1;

        .main_joinTitleColor {
            color: #45FFA6;
        }
    }

    .main_joinSubTitle {
        color: #A0A0A0;
        font-size: 0.8vw;
    }

    .main_joinButton {
        color: #45FFA6;
        font-size: 1.2vw;
        border: 0.138vw solid #45FFA6;
        border-radius: 0.7vw;
        width: 19vw;
        height: 3vw;
        background: transparent;
        box-shadow: transparent 0 0 0 0;
        transition: all 600ms linear(
                0 0%, 0.22 2.1%, 0.86 6.5%, 1.11 8.6%, 1.3 10.7%, 1.35 11.8%, 1.37 12.9%,
                1.37 13.7%, 1.36 14.5%, 1.32 16.2%, 1.03 21.8%, 0.94 24%, 0.89 25.9%, 0.88
                26.85%, 0.87 27.8%, 0.87 29.25%, 0.88 30.7%, 0.91 32.4%, 0.98 36.4%, 1.01
                38.3%, 1.04 40.5%, 1.05 42.7%, 1.05 44.1%, 1.04 45.7%, 1 53.3%, 0.99 55.4%,
                0.98 57.5%, 0.99 60.7%, 1 68.1%, 1.01 72.2%, 1 86.7%, 1 100%
        );

        &:hover {
            transform: translateY(-0.5vw);
            box-shadow: #121212 0 0.5vw 0 0, #45FFA6 0 0.5vw 0 0.06vw;
        }
    }
}

@keyframes bounce {
    0% {
        transform: translateY(-8px);
    }
    50% {
        transform: translateY(-12px);
    }
    100% {
        transform: translateY(-8px);
    }
}

@keyframes scrolling {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.neva_aboutAs_counters {
    width: 100%;
    display: flex;
}

.neva_aboutAs_counter {
    height: 8vw;
    display: flex;
    flex-direction: column;
    border-left: 0.13vw dashed #45FFA6;
    padding: 0 5vw 0 1vw;
    justify-content: center;
}
.neva_aboutAs_counter:first-child {
    border: none;
}
.neva_aboutAs_counterTitle {
    font-size: 6vw;
    line-height: 1;
}
.neva_aboutAs_counterSubTitle {
    color: #A0A0A0;
    font-size: 0.8vw;
}